import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    container,
    grid,
    sectionBox,
    radiusBox,
    credits,
    navigation,
    hidden,
    transactions,
    tableGrid,
    title,
    titleLeft,
    plus,
    minus,
    loader,
    initialLoading,
    topWrapper,
    creditsValue,
    label,
    value,
    button,
    balance,
    packageElement,
    packageTitle,
    cell,
    first,
    last,
    sliderContainer,
    slider,
    next,
    prev,
    small,
    big,
    noPackages,
} from './client-credits.module.scss';

import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { ISection } from '../../models/section.model';
import { TLocale } from '../../locale';
import { IPointTransactionModel } from '../../models/point-transaction.model';

import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import { getUserTokenData } from '../../utils/get-user-token-data';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useSwiperLoading } from '../../hooks/use-swiper-loading';
import { useClientPermission } from '../../hooks/use-client-permission';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import DashboardHeader from '../molecules/dashboard-header';
import Table, { ITableCell, ITableProps } from '../organisms/table';
import Loader from '../atoms/loader';
import Button from '../atoms/button';
import DetailsColumn from '../atoms/details-column';
import Slider from '../hoc/slider';
import NoPermissionInfo from '../organisms/no-permission-info';
import IconFactory from '../hoc/icon-factory';

interface IClientCreditsQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
}

interface IClientCredits {
    className?: string;
    section: ISection;
}

const ClientCredits: React.FC<IClientCredits> = ({ className = '', section }) => {
    const t = useTranslations('ClientCredits');
    const { style, sectionId, css } = section;
    const client = useClient({ dontSkip: true });
    const canShop = useClientPermission(['can-shop']);
    const { allPage } = useStaticQuery<IClientCreditsQueryResult>(query);
    const pricingPage = usePagePathname(allPage);
    const tokenData = getUserTokenData();
    const { loading, navigationNextRef, navigationPrevRef, handleSwiperInit } = useSwiperLoading();

    const packages = client.data ? client.data.packages : [];

    const { status, isInitialLoading, items, paginationPaths } = useList({
        endpoint: '/point-transaction?sort=-transaction_id',
        token: tokenData?.token,
        perPage: packages.length > 3 ? 6 : 5,
    });

    const tableRows = getRowElements(t, items as IPointTransactionModel[], packages.length);

    useEffect(() => {
        if (!client.dashboard.data) return;
        client.dashboard.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!client.get.data) return;
        client.get.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!canShop) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${isInitialLoading ? initialLoading : ''}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading ? (
                <Loader className={loader} />
            ) : (
                <div className={container}>
                    <div className={credits}>
                        <DashboardHeader className={`${title} ${titleLeft}`} hint={t.creditsHint}>
                            {t.creditsTitle}
                        </DashboardHeader>
                        <RadiusBox className={radiusBox}>
                            <div className={topWrapper}>
                                <div className={creditsValue}>
                                    <p className={label}>{t.currentBalance}</p>
                                    <p className={value}>{client.dashboard.data?.points}</p>
                                </div>
                                <Button
                                    as={'link'}
                                    to={pricingPage}
                                    stylePreset={'primary'}
                                    className={button}
                                >
                                    {t.buy}
                                </Button>
                            </div>
                            <p className={label}>{t.packages}</p>
                            <div className={sliderContainer}>
                                {packages.length > 0 ? (
                                    <>
                                        <Slider
                                            loading={loading}
                                            className={slider}
                                            spaceBetween={0}
                                            slidesPerView={3}
                                            navigation={{
                                                prevEl: navigationPrevRef.current,
                                                nextEl: navigationNextRef.current,
                                            }}
                                            onInit={handleSwiperInit}
                                        >
                                            {packages.map((item) => {
                                                return (
                                                    <div className={packageElement}>
                                                        <p className={packageTitle}>{item.name}</p>
                                                        <DetailsColumn
                                                            label={t.start}
                                                            values={[
                                                                getDateFromUnixTimestamp(
                                                                    item.start,
                                                                    'dash'
                                                                ),
                                                            ]}
                                                        />
                                                        <DetailsColumn
                                                            label={t.finish}
                                                            values={[
                                                                getDateFromUnixTimestamp(
                                                                    item.finish,
                                                                    'dash'
                                                                ),
                                                            ]}
                                                        />
                                                        <DetailsColumn
                                                            label={t.creditsShort}
                                                            values={[
                                                                `${item.creditsAvailable}/${item.creditsPackage}`,
                                                            ]}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                        <div
                                            className={`${navigation} ${
                                                packages.length < 4 ? hidden : ''
                                            }`}
                                        >
                                            <Button
                                                shape="circle"
                                                ref={navigationPrevRef}
                                                stylePreset="secondary"
                                                className={prev}
                                            >
                                                <IconFactory icon="chevron" />
                                            </Button>
                                            <Button
                                                shape="circle"
                                                ref={navigationNextRef}
                                                stylePreset="secondary"
                                                className={next}
                                            >
                                                <IconFactory icon="chevron" />
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <p className={noPackages}>{t.noPackages}</p>
                                )}
                            </div>
                        </RadiusBox>
                    </div>
                    <div className={transactions}>
                        <DashboardHeader
                            className={title}
                            hint={t.transactionsHint('LINK DO REGULAMINU')}
                        >
                            {t.transactionsTitle}
                        </DashboardHeader>
                        <Table
                            tableClassName={tableGrid}
                            headerCells={getHeaderElements(t)}
                            rows={tableRows}
                            status={status}
                            paginationPaths={paginationPaths}
                        />
                    </div>
                </div>
            )}
        </Section>
    );
};

const getHeaderElements = (t: TLocale['ClientCredits']) => {
    return [{ label: t.name }, { label: t.date }, { label: t.credits }, { label: t.balance }];
};

const getRowElements = (
    t: TLocale['ClientCredits'],
    items: IPointTransactionModel[],
    packagesCount: number
) => {
    const rowElements: ITableProps['rows'] = [];

    items.forEach((item) => {
        const element: ITableCell[] = [
            {
                type: 'data',
                label: t.name,
                value: item.description,
                className: `${cell} ${first} ${packagesCount > 3 ? small : big}`,
            },
            {
                type: 'data',
                label: t.date,
                value: `${getDateFromUnixTimestamp(item.createdAt, 'dash')}`,
                className: `${cell} ${packagesCount > 3 ? small : big}`,
            },
            {
                type: 'data',
                label: t.credits,
                value: getAmountString(item.amount),
                valueClassName: item.amount.toString().includes('-') ? minus : plus,
                className: `${cell} ${packagesCount > 3 ? small : big}`,
            },
            {
                type: 'data',
                label: t.balance,
                value: item.balance,
                valueClassName: balance,
                className: `${cell} ${last} ${packagesCount > 3 ? small : big}`,
            },
        ];

        rowElements.push(element);
    });

    return rowElements;
};

const getAmountString = (amount: number) => {
    if (amount.toString().includes('-')) {
        return amount.toString().replace('-', '- ');
    }

    return `+ ${amount}`;
};

export const query = graphql`
    query {
        allPage(filter: { type: { in: ["pricing"] } }) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientCredits;
