import React, { useEffect, useState } from 'react';

export function useSwiperLoading() {
    const [loading, setLoading] = useState(false);
    const [, setIsSwiperInitialized] = useState(false);

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const paginationRef = React.useRef(null);

    useEffect(() => {
        const refs = [navigationPrevRef, navigationNextRef, paginationRef];

        setLoading(
            refs.some((ref) => {
                return ref.current !== null;
            })
        );
    }, []);

    return {
        loading,
        navigationPrevRef,
        navigationNextRef,
        paginationRef,
        handleSwiperInit: () => setIsSwiperInitialized(true),
    };
}
