// extracted by mini-css-extract-plugin
export var backgroundAnimation = "client-credits-module--backgroundAnimation--fc435";
export var balance = "client-credits-module--balance--940b3";
export var big = "client-credits-module--big--b1af4";
export var button = "client-credits-module--button--45175";
export var cell = "client-credits-module--cell--c6fbd";
export var container = "client-credits-module--container--6eb46";
export var credits = "client-credits-module--credits--65cdc";
export var creditsValue = "client-credits-module--credits-value--35a24";
export var first = "client-credits-module--first--44c1f";
export var grid = "client-credits-module--grid--cd269";
export var hidden = "client-credits-module--hidden--8dd61";
export var initialLoading = "client-credits-module--initial-loading--ffb7c";
export var label = "client-credits-module--label--e1a68";
export var last = "client-credits-module--last--a5b10";
export var loader = "client-credits-module--loader--21414";
export var loading = "client-credits-module--loading--c7774";
export var minus = "client-credits-module--minus--d2c97";
export var navigation = "client-credits-module--navigation--b0254";
export var next = "client-credits-module--next--57f3f";
export var noPackages = "client-credits-module--no-packages--720af";
export var packageElement = "client-credits-module--package-element--b7371";
export var packageTitle = "client-credits-module--package-title--5c1a8";
export var plus = "client-credits-module--plus--04c14";
export var prev = "client-credits-module--prev--ffda1";
export var radiusBox = "client-credits-module--radius-box--0a176";
export var sectionBox = "client-credits-module--section-box--32f0a";
export var slider = "client-credits-module--slider--0fcd3";
export var sliderContainer = "client-credits-module--slider-container--2e1d8";
export var small = "client-credits-module--small--5776a";
export var tableGrid = "client-credits-module--table-grid--ba417";
export var title = "client-credits-module--title--9b988";
export var titleLeft = "client-credits-module--title-left--4a025";
export var topWrapper = "client-credits-module--top-wrapper--d0eef";
export var transactions = "client-credits-module--transactions--80ca1";
export var value = "client-credits-module--value--a7d9a";